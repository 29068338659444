<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6">
          <div class="mb-2 text-uppercase font-weight-bold body-2 primary--text"> {{ companies.topline }} </div>
          <h2 class="text-h3 text-lg-h2"> {{ companies.title }} </h2>
          <div class="mt-5 text-h6 text-lg-h5"> {{ companies.description }} </div>
          <!-- <div class="mt-4">
            <router-link
              to="#"
              class="text-decoration-none font-weight-bold text-h6"
            >
              {{ companies.action }}
            </router-link>
          </div> -->
        </v-col>
        <v-col cols="12" md="6">
          <div class="text-center logos5 pt-4">
            <v-row dense>
              <v-col cols="12" sm="6" xl="3" v-for="(company, i) in companies.company" :key="i">
                <v-badge bordered class="primary--text" :color="company.AEOStatus == 'CEO' ? 'primary' : 'primary'"
                  text-color="black" content="TIER-I">
                  <a :href="company.website" target="_blank">
                    <!-- <v-sheet color="transparent"> -->
                    <img :src="`${imageBase}${company.logo.url}`" />
                    <!-- </v-sheet> -->
                  </a>
                </v-badge>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-badge
                  bordered
                  class="primary--text"
                  color="yellow"
                  text-color="black"
                  content="AEO"
                  overlap
                >
                  <a href="#">
                    <v-sheet color="surface">
                      <img :src="require('@/assets/images/logos/logo-4.svg')" />
                    </v-sheet>
                  </a>
                </v-badge>
              </v-col> -->
              <!-- <v-col cols="12" sm="6">
                <v-badge bordered color="primary" content="CEO" overlap>
                  <a href="#">
                    <v-sheet color="surface">
                      <img :src="require('@/assets/images/logos/logo-4.svg')" />
                    </v-sheet>
                  </a>
                </v-badge>
              </v-col> -->
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import moment from "moment";
import aeoApi from "@/api/AeoAPI";

export default {
  name: "AEOCompanies",

  async mounted() {
    const companies = await aeoApi.get({ resource: "aeo-companies?_limit=20" });
    this.companies = companies.data;
  },
  data() {
    return {
      companies: {
        topline: "AEO COMPANIES",
        title: "Current AEO Status Holders",
        description:
          "Several companies have participated in the AEO pilot project and completed the application process for the AEO Tier-I status. They will be awarded the AEO Tier-I status in due course upon the approval of the AEO committee.",
      },
      imageBase: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.logos5 {
  img {
    filter: grayscale(100%) brightness(2);
    width: 180px;
  }

  .theme--dark img {
    filter: grayscale(100%) invert(1);
  }

  a:hover {
    img {
      filter: none;
    }
  }
}
</style>
