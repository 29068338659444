<template>
  <v-sheet color="transparent">
    <Toolbar />
    <v-container class="py-2 py-md-6">
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="7">
          <h1 class="text-h3 text-sm-h3 text-lg-h2 text-xl-h1 mt-xl-4">
            Sri Lanka Customs <span class="primary--text">AEO</span> Programme
          </h1>
          <h3
            class="mt-2 mb-2 text-h6 text-sm-h5 secondary--text font-weight-bold"
          >
            Recognizing True Trade Partners
          </h3>

          <div class="mt-5 text-h6 text-lg-h5">
            Authorized Economic Operator (AEO) Programme is the most prominent
            trade facilitation programme of Sri Lanka Customs, recognizing and
            facilitating the most compliant and secure operators of the supply
            chain.
          </div>
          <!-- <p>
            Authorized Economic Operator (AEO) Programme is the most prominent
            trade facilitation programme of Sri Lanka Customs, recognizing and
            facilitating the most compliant and secure operators in
            international supply chain.
          </p> -->
          <div class="mt-4 mt-xl-6">
            <!-- <v-btn
              x-large
              class="w-full my-1 mx-sm-1 mx-md-0 w-sm-auto"
              color="primary"
              :href="application.href"
              >Get started</v-btn
            > -->
            <v-btn
              x-large
              class="w-full my-1 mx-sm-1 w-sm-auto"
              target="_blank"
              :href="link.href"
              >Operational Guide</v-btn
            >
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="5"
          xl="4"
          offset-lg="1"
          offset-xl="0"
          class="d-none d-md-block"
        >
          <div class="v-card--hero">
            <v-lazy min-height="315" min-width="560">
              <iframe
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="v-card--hero__iframe"
                frameborder="0"
                src="https://www.youtube.com/embed/OovQdPzjluY"
                title="Authorized Economic Operator Programme"
              />
            </v-lazy>
          </div>
          <div class="pt-3 pb-2 text-center caption">
            Video by
            <a href="https://aeo.customs.gov.lk" target="_blank"
              >Sri Lanka Customs</a
            >
            Risk Management Deractorate | AEO Team
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import Toolbar from "./Toolbar";
import aeoApi from "../../api/AeoAPI";

const BASE_URL = process.env.VUE_APP_BASE_URL_IMAGE;

export default {
  components: {
    Toolbar,
  },

  data() {
    return {
      link: {
        href: undefined,
      },
      application: {
        href: undefined,
      },
    };
  },

  async mounted() {
    const documents = await aeoApi.get({
      resource: "documents",
    });

    this.documents = documents.data;

    this.link = this.documents
      .map(function (item) {
        return {
          label: item.name,
          href: `${BASE_URL}${item.file.url}`,
        };
      })
      .filter((item) => item.label == "AEO Operational Guide")[0];

    this.application = this.documents
      .map(function (item) {
        return {
          label: item.name,
          href: `${BASE_URL}${item.file.url}`,
        };
      })
      .filter((item) => item.label == "Application")[0];
  },
};
</script>
<style lang="sass" scoped>
.v-card--hero
  border-radius: 6px
  height: 0
  margin: 0
  overflow: hidden
  padding-bottom: 70%
  position: relative
  width: 100%

  &__iframe
    display: block
    vertical-align: middle
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
</style>
