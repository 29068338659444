<template>
  <v-sheet>
    <v-container class="py-4">
      <div class="mb-2 text-uppercase font-weight-bold body-2 primary--text">
        {{ aeoNews.topline }}
      </div>
      <h2 class="text-h3 text-lg-h2">
        {{ aeoNews.title }}
      </h2>
      <div class="mt-4 mb-4 text-h6 text-lg-h5">
        {{ aeoNews.description }}
      </div>

      <vue-horizontal-list
        :items="aeoNews.articles.slice().reverse()"
        :options="options"
      >
        <template v-slot:default="{ item }">
          <v-card class="item" @click="goToArticle(item.article.id)">
            <div class="hover-wrapper">
              <v-img
                :src="`${imageBase}${item.article.cover.url}`"
                @error="setAltImg"
                height="300"
              />

              <div class="pa-2 pa-md-3">
                <div
                  class="
                    mb-1
                    text-uppercase
                    font-weight-black
                    text-body-2
                    primary--text
                  "
                >
                  {{ item.article.topline }}
                </div>
                <div class="news-title font-weight-bold text-h6 text-md-h5">
                  {{ item.article.title | stringlimit(100) }}
                </div>
                <div
                  class="news-description mt-1 mb-4 secondary--text text-body-1"
                >
                  {{ item.article.description | stringlimit(200) }}
                </div>

                <div class="d-flex align-center">
                  <v-avatar small class="mr-1" size="38">
                    <img
                      :src="`${imageBase}${item.article.authors_photo.url}`"
                      alt=""
                    />
                  </v-avatar>
                  <div>
                    <span class="font-weight-bold text-body-2">{{
                      item.article.authors_name
                    }}</span>
                    <v-spacer />
                    <div class="secondary--text text--lighten-1 text-caption">
                      {{ getReadableDate(item.article.article_date) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </template>
      </vue-horizontal-list>
    </v-container>
  </v-sheet>
</template>

<script>
import VueHorizontalList from "vue-horizontal-list";
import moment from "moment";
import aeoApi from "@/api/AeoAPI";

export default {
  name: "News",
  components: {
    VueHorizontalList,
  },
  async mounted() {
    const aeoNews = await aeoApi.get({ resource: "aeo-news?_limit=10" });
    this.aeoNews = aeoNews.data;
  },
  data() {
    return {
      aeoNews: {
        topline: "",
        title: "",
        description: "",
        articles: [],
      },
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { size: 3 },
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 24,
        },
        position: {
          start: 1,
        },
        autoplay: { play: true, repeat: true, speed: 4000 },
      },
      imageBase: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },

  methods: {
    getReadableDate(date) {
      return moment(new Date(date)).format("DD MMM, YYYY");
    },

    setAltImg(event) {
      event.target.src = "../../../../assets/images/placeholder.jpg";
    },

    goToArticle(id) {
      this.$router.push(`article/${id}`);
    },
  },
};
</script>
<style scoped>
.hover-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.hover-wrapper .v-image {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hover-wrapper:hover .v-image {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.news-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
