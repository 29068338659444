<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" md="6">
          <div class="mb-2 text-uppercase font-weight-bold body-2 primary--text"> APPLICATION PROCESS </div>
          <h2 class="text-h3 text-lg-h2 mt-xl-4"> {{ applicationProcess.title }} </h2>
          <div class="mt-5 text-h6 text-lg-h5"> {{ applicationProcess.description }} </div>
          <v-responsive class="mt-8" max-width="600">
            <div class="w-full d-flex flex-column flex-sm-row">
              <!-- <v-text-field
                outlined
                solo
                label="Your email"
                height="64"
                class="mr-sm-2"
              /> -->
              <v-btn x-large color="primary" target="_blank" :href="application.href" min-width="180"> {{
                applicationProcess.button_text }} </v-btn>
            </div>
          </v-responsive>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="pa-2">
            <v-list v-for="(item, i) in applicationProcess.list" :key="i" two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon large class="lighten-4 primary--text"> {{ item.icon_code }} </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle class="wrap-text">{{ item.content }} </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider inset />
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import aeoApi from "../../api/AeoAPI";
const BASE_URL = process.env.VUE_APP_BASE_URL_IMAGE;

export default {
  props: {
    applicationProcess: {
      type: Object,
      default: () => ({
        topline: "APPLICATION PROCESS",
        title: "How to Apply?",
        description:
          "Sri Lanka Customs will evaluate your application thoroughly and decide on granting the relevant status",
        list: [],
        button_text: "Create an Account",
      }),
    },
  },

  data() {
    return {
      link: {
        href: undefined,
      },
      application: {
        href: undefined,
      },
    };
  },

  async mounted() {
    const documents = await aeoApi.get({
      resource: "documents",
    });

    this.documents = documents.data;

    this.link = this.documents
      .map(function (item) {
        return {
          label: item.name,
          href: `${BASE_URL}${item.file.url}`,
        };
      })
      .filter((item) => item.label == "AEO Operational Guide")[0];

    this.application = this.documents
      .map(function (item) {
        return {
          label: item.name,
          href: `${BASE_URL}${item.file.url}`,
        };
      })
      .filter((item) => item.label == "AEO Application")[0];
  },
};
</script>
<style scoped>
.wrap-text {
  white-space: normal;
}
</style>
