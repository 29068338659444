<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div>
        <div class="mb-2 text-uppercase font-weight-bold body-2 primary--text">
          {{ benefits.topline }}
        </div>
        <h2 class="text-h3 text-lg-h2">
          {{ benefits.title }}
        </h2>
        <v-responsive max-width="1200">
          <div class="mt-4 text-h6 text-lg-h5">
            {{ benefits.description }}
          </div>
        </v-responsive>
      </div>
      <v-row class="mt-4">
        <v-col v-for="(item, i) in benefits.list" :key="i" cols="12" md="6">
          <div class="d-flex">
            <div class="mr-2">
              <v-sheet color="surface" rounded class="pa-2">
                <v-icon>{{ item.icon_code }}</v-icon>
              </v-sheet>
            </div>
            <div>
              <div class="text-h5">
                {{ item.title }}
              </div>
              <div class="mt-1 text-h6">
                {{ item.content }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    benefits: {
      type: Object,
      default: () => ({
        topline: "Benefits",
        title: "Why should you be a part of AEO Programme",
        description: "",
        list: [],
      }),
    },
  },

  data() {
    return {};
  },
};
</script>
