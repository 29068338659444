<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" lg="6" xl="4">
          <v-sheet elevation="6" class="pa-2" rounded>
            <v-carousel v-model="model" :show-arrows="true" cycle height="440" hide-delimiters hide-delimiter-background
              :light="!$vuetify.theme.isDark">
              <v-carousel-item v-for="(item, i) in carousel" :key="i">
                <v-sheet>
                  <v-responsive max-width="400" class="mx-auto text-center">
                    <!-- <div class="mt-2 text-h5">
                      {{ item.title }}
                    </div> -->
                    <!-- <a href="#" class="text-center">
                      <img src="@/assets/images/logos/AEO_2.svg" height="300" />
                    </a> -->
                    <div class="pt-4 text-center align-middle">
                      <v-sheet v-if="item.title == 'CEO'" color="">
                        <img height="200px" :src="require('@/assets/images/logos/AEO_TIER-I.svg')" />
                      </v-sheet>
                      <v-sheet v-if="item.title == 'AEO'" color="">
                        <img height="200px" :src="require('@/assets/images/logos/AEO_2.png')" />
                      </v-sheet>
                    </div>
                    <div class="
                        mt-6
                        mb-1
                        text-body-1 text-md-h6
                        font-weight-bold
                        secondary--text
                      "> {{ item.text }} </div>
                    <div class="
                        caption
                        text-sm-body-2
                        secondary--text
                        text-md-body-2
                      "> {{ item.description }} </div>
                    <!-- <div class="mt-4">
                      <router-link
                        :to="item.link.to"
                        class="text-decoration-none font-weight-bold text-h6"
                      >
                        {{ item.link.title }}
                      </router-link>
                    </div> -->
                  </v-responsive>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-col>
        <v-col cols="12" lg="6" offset-xl="2" xl="6">
          <div class="
              mb-2
              text-uppercase
              font-weight-bold
              text-body-2
              primary--text
            "> Programme Structure </div>
          <h2 class="mt-0 text-h3 text-lg-h2 mt-lg-3 mt-xl-3"> Two Tiered Approach </h2>
          <div class="mt-6 text-h6 text-lg-h5"> The AEO programme of Sri Lanka Customs is structured as a two-tiered
            programme. Authorized Economic Operator Tier I(AEO Tier-I) is the first tier where the applicants have to
            demonstrate their operational compliance levels in order to obtain the AEO Tier-I status. After enjoying the
            AEO Tier-I status for at least one year applicants can apply for the prestigious AEO Tier-II status where
            the supply chain security standards of the applicants are assessed. </div>
          <v-btn color="primary" target="_blank" :href="link.href" x-large class="mt-6"> Operational Guide </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import aeoApi from "../../api/AeoAPI";

const BASE_URL = process.env.VUE_APP_BASE_URL_IMAGE;

export default {
  async mounted() {
    const documents = await aeoApi.get({
      resource: "documents",
    });

    this.documents = documents.data;

    this.link = this.documents
      .map(function (item) {
        return {
          label: item.name,
          href: `${BASE_URL}${item.file.url}`,
        };
      })
      .filter((item) => item.label == "AEO Operational Guide")[0];
  },

  data: () => ({
    model: 0,
    link: {},
    carousel: [
      {
        title: "AEO",
        logo: "@/assets/images/logos/AEO_2.svg",
        text: "Authorized Economic Operator Tier-II (AEO Tier-II)",
        description:
          "Prestigious Authorized Economic Operator (AEO Tier-II) status is offered to any operator who demonstrates higher levels of operational compliance along with high levels of supply chain security standards. Operating as a Authorized Economic Operator Tier I for at least one year is a prerequisite for applying for the AEO Tier II status.",
        link: {
          title: "Learn More",
          to: "#",
        },
      },
      {
        title: "CEO",
        logo: "@/assets/images/logos/CEO_2.svg",
        text: "Authorized Economic Operator Tier-I (AEO Tier-I)",
        description:
          "Authorized Economic Operator(AEO Tier-I), status is awarded to the Exporters, Importers and Cargo Clearing Agents who demonstrate higher levels of operational compliance in the areas of legal, finance & managing commercial records. ",
        link: {
          title: "Learn More",
          to: "#",
        },
      },
    ],
  }),
};
</script>
<style scoped>
img {
  /* filter: grayscale(100%) brightness(2);
  width: 180px; */
}
</style>
