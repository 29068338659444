<!--
Component:  Home.vue

Purpose:    The main page users see when they navigate to the site. Shows three main sections:
            1. Hero: an eye-catcher that contains the sign up box
            2. Feature: A section that describes to qualities of ScopeJS
            3. Faq: The frequently asked questions

Props:      None
-->
<template>
  <div>
    <Hero />
    <Logos />
    <News />
    <ProgrammeStructure />
    <AEOCompanies />
    <ApplicationProcess :applicationProcess="applicationProcess" />
    <Benefits :benefits="benefits" />
    <Testimonials :testimonials="testimonials" />
    <Team :aeo-committee="aeoCommittee" />
    <Faq :faq="faq"></Faq>
    <Footer></Footer>
  </div>
</template>

<script>
import Hero from "@/components/core/Header";
import Logos from "@/components/core/Logos2";
import News from "@/components/core/News";
import ProgrammeStructure from "@/components/core/ProgrammeStructure";
import AEOCompanies from "@/components/core/AEOCompanies";
import ApplicationProcess from "@/components/core/ApplicationProcess";
import Benefits from "@/components/core/Benefits";
import Testimonials from "@/components/core/Testimonial";
import Team from "@/components/core/AEOCommittee";
import Faq from "@/components/core/Faq";
import Footer from "@/components/core/Footer";

import aeoApi from "@/api/AeoAPI";

export default {
  components: {
    Hero,
    Logos,
    AEOCompanies,
    Team,
    News,
    Faq,
    Benefits,
    Testimonials,
    ProgrammeStructure,
    ApplicationProcess,
    Footer,
  },

  async mounted() {
    const benefits = await aeoApi.get({ resource: "benefits" });
    const aeoCommittee = await aeoApi.get({ resource: "aeo-committee" });
    const testimonials = await aeoApi.get({ resource: "testimonials" });
    const applicationProcess = await aeoApi.get({
      resource: "application-process",
    });
    const faq = await aeoApi.get({ resource: "faqs" });

    this.applicationProcess = applicationProcess.data;
    this.benefits = benefits.data;
    this.testimonials = testimonials.data;
    this.aeoCommittee = aeoCommittee.data;
    this.faq = faq.data;
  },

  data() {
    return {
      faq: {},
      aeoCommittee: {},
      testimonials: {},
      benefits: {},
      applicationProcess: {},
      aeoNews: {},
    };
  },
};
</script>
