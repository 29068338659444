<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <div class="mb-2 text-uppercase font-weight-bold body-2 primary--text">
          {{ testimonials.topline }}
        </div>
        <h2 class="text-h3 text-lg-h2">
          {{ testimonials.title }}
        </h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="mt-4 text-h6 text-lg-h5">
            {{ testimonials.description }}
          </div>
        </v-responsive>
      </div>
      <v-row class="mt-6">
        <v-col
          v-for="(item, i) in testimonials.Testimonial"
          :key="i"
          cols="12"
          sm="6"
          lg="6"
        >
          <v-sheet>
            <v-card-title>
              <v-sheet
                color="primary"
                width="60"
                height="48"
                class="
                  justify-center
                  mx-auto
                  d-flex
                  align-start
                  text-h1
                  white--text
                  mt-n6
                  position-relative
                "
                style="border-radius: 100%"
              >
                <div class="position-absolute" style="top: 16px">“</div>
              </v-sheet>
            </v-card-title>
            <div class="pa-3">
              <div class="text-h6 secondary--text">
                {{ item.description }}
              </div>

              <div class="mt-4 d-flex align-center">
                <div class="mr-2">
                  <v-avatar size="50">
                    <v-img :src="`${imageBase}${item.person.image.url}`" />
                  </v-avatar>
                </div>
                <div class="text-left">
                  <div class="text-h7 text-h6 font-weight-bold">
                    {{ item.person.name }}
                  </div>
                  <div class="text-h7 secondary--text">
                    {{ item.person.designation }}
                  </div>
                  <div class="text-h7 secondary--text">
                    {{ item.person.title }}
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    testimonials: {
      default: () => ({
        topline: "WORKING WITH US",
        title: "Thoughts of Partners and Stakeholders",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus impedit error labore doloremque fugit! Dolor fugit molestiae vero quos quisquam nobis, eos debitis magni omnis ea incidunt amet voluptate dignissimos!",
        testimonials: {
          Testimonial: [
            {
              person: {
                name: "Major General (Retd.) G.V. Ravipriya",
                designation: "Director General of Customs",
                title: "Chairman - AEO Committee",
                image: {
                  url: "../../assets/images/sample/sample-avatar.jpg",
                },
              },
              description:
                "As the government authority which controls the movement of goods across Sri Lankan borders; we see this programme as a wonderful opportunity to create a hazzel free business environment to our legitimate trading community. Apply for the programme and show us your competency. As SL Customs we promise that we will provide you with the maximum benefits that we can offer for such companies.",
            },
          ],
        },
      }),
    },
  },

  data() {
    return {
      imageBase: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
};
</script>
