<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <div class="text-center">
        <div class="text-uppercase font-weight-bold body-2 primary--text mb-2">
          {{ faq.topline }}
        </div>
        <h2 class="text-h3 text-lg-h2">
          {{ faq.title }}
        </h2>
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">
            {{ faq.subtitle }}
          </div>
        </v-responsive>
      </div>

      <v-expansion-panels v-model="panel" multiple class="mt-8">
        <v-expansion-panel v-for="(item, i) in faq.qAndAs" :key="i">
          <v-expansion-panel-header class="text-h5">
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    faq: {
      type: Object,
      default: () => ({
        title: "Frequently Asked Questions",
        subtitle: "",
        qAndAs: [
          {
            question: "Who is an Authorized Economic Operator (AEO)?",
            answer:
              "An authorized Economic Operator (AEO) is any party involved in the international supply chain; that has been recognized by the respective national Customs administration as a compliant trader for maintaining high supply chain security standards. AEOs may include importers, exporters, customs brokers, carriers, consolidators, intermediaries, ports, airports, terminal operators, integrated operators, manufacturers, warehouses and distributors.",
          },
        ],
      }),
    },
  },

  data() {
    return {
      panel: [0],
    };
  },
};
</script>
