<template>
  <v-container class="py-4 py-lg-8">
    <div class="mb-2 text-uppercase font-weight-bold body-2 primary--text">
      AEO COMMITTEE
    </div>
    <h2 class="mb-3 text-h3 text-lg-h2">{{ aeoCommittee.title }}</h2>
    <div class="mt-4 text-h6 text-lg-h5">
      {{ aeoCommittee.description }}
    </div>

    <v-card class="mt-6 pa-4">
      <v-row>
        <v-col
          v-for="(item, i) in aeoCommittee.members"
          :key="i"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <div class="d-flex align-center">
            <div class="mr-2">
              <v-avatar size="80">
                <v-img
                  v-if="item.image != null"
                  :src="`${imageBase}${item.image.url}`"
                  v-on:error="imageUrlAlt"
                />
                <v-img
                  v-if="item.image == null"
                  src="../../assets/images/sample/sample-avatar.jpg"
                />
              </v-avatar>
            </div>
            <div>
              <div class="text-h7 secondary--text">
                {{ item.designation }}
              </div>
              <div class="text-h7 text-md-h6 font-weight-bold">
                {{ item.name }}
              </div>
              <div class="text-h7 text-md-h7 font-weight-medium gray--text">
                {{ item.title }}
              </div>
              <div class="text-h7 text-md-h7 secondary--text">
                {{ item.description }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    aeoCommittee: {
      default: () => ({
        topline: "Committee",
        title: "AEO Committee",
        description: "",
        members: [
          {
            name: "",
            designation: "",
            title: "",
            description: "",
            image: "../../assets/images/sample/sample-avatar.jpg",
          },
          {
            name: "",
            designation: "",
            title: "",
            description: "",
            image: "../../assets/images/sample/sample-avatar.jpg",
          },
          {
            name: "",
            designation: "",
            title: "",
            description: "",
            image: "../../assets/images/sample/sample-avatar.jpg",
          },
        ],
      }),
    },
  },

  data() {
    return {
      imageBase: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },

  methods: {
    imageUrlAlt() {
      return "/src/assets/images/sample/sample-avatar.jpg";
    },
  },
};
</script>
